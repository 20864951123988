import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { PageLayout } from "../../layouts/page";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PageLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "politique-de-vie-privée"
    }}>{`Politique de vie privée`}</h1>
    <h2 {...{
      "id": "entité"
    }}>{`Entité`}</h2>
    <p>{`Lleed & Partners SàRL, aussi dénomée sous le nom de Lleed & Partners, L&P, "Nous" dans le présent document, est une compagnie à responsabilité limitée de droit suisse.`}</p>
    <p><a parentName="p" {...{
        "href": "/legal/#entity"
      }}>{`Plus d'informations sur Lleed & Partners`}</a></p>
    <h2 {...{
      "id": "informations-que-nous-collectons"
    }}>{`Informations que nous collectons`}</h2>
    <p>{`Lorsque vous utilisez ce site web, Lleed & Partners peut collecter les données suivantes à votre propos ainsi qu'à propos de votre utilisation.`}</p>
    <h3 {...{
      "id": "statistiques-dutilisation"
    }}>{`Statistiques d'utilisation`}</h3>
    <p>{`En parcourant ce site web, et si vous avez opté pour la collection de statistiques d'utilisation, nous collecterons les informations suivantes :`}</p>
    <ul>
      <li parentName="ul">{`Type d'appareil (PC ou Mobile)`}</li>
      <li parentName="ul">{`Localisation approximative (Pays/Région)`}</li>
      <li parentName="ul">{`Données d'usage web, notamment les pages que vous avez visitées, ce sur quoi vous avez cliqué et jusqu'où vous avez fait défiler une page.`}</li>
    </ul>
    <p>{`Ces informations utilisent un identifiant d'appareil anonyme, qui est strictement confidentiel et n'est pas partagé avec d'autres services.
Si vous n'avez pas opté pour la collection de statistiques d'utilisation, ou avez retiré votre consentement pour la collecte de ces données, nous ne collecterons pas vos données d'utilisation.`}</p>
    <p>{`Ces statistiques d'utilisation nous permettent de mieux comprendre quelles parties de notre site web sont importantes, afin d'améliorer l'expérience utilisateur à l'avenir. Elles ne sont pas utilisées à des fins marketing ou publicitaires.`}</p>
    <h3 {...{
      "id": "contact"
    }}>{`Contact`}</h3>
    <p>{`Quand vous nous contactez via un formulaire de ce site, nous serons amenés à collecter les informations suivantes :`}</p>
    <ul>
      <li parentName="ul">{`Nom`}</li>
      <li parentName="ul">{`Numéro de téléphone`}</li>
      <li parentName="ul">{`Nom de l'entreprise`}</li>
      <li parentName="ul">{`Toute autre donnée clairement explicité dans les formulaires`}</li>
    </ul>
    <p>{`Etant donné que ces formulaires sont ensuite triés par Netlify, notamment pour éviter le spam, vous devez autoriser le traitement automatique de vos données. Ces données sont ensuite rapidement supprimées par Netlify.
Si vous préférez ne pas autoriser un tel traitement, nous vous invitons à nous contacter par courrier, courriel ou téléphone selon les moyens disponibles.`}</p>
    <p>{`Ces informations nous permettent par la suite de répondre au mieux à votre demande.`}</p>
    <h2 {...{
      "id": "suppression-et-modification-dinformation"
    }}>{`Suppression et modification d'information`}</h2>
    <p>{`Un utilisateur peut, à n'importe quel moment, demander à consulter, modifier ou supprimer des données le concernant, qu'elles soient stockées par nous-mêmes ou par l'un de nos services tiers. Pour faire valoir votre droit, merci d'utiliser notre `}<a parentName="p" {...{
        "href": "/contact/"
      }}>{`portail de contact`}</a>{`.`}</p>
    <h2 {...{
      "id": "cookies"
    }}>{`Cookies`}</h2>
    <h3 {...{
      "id": "ajs_anonymous_id"
    }}>{`ajs_anonymous_id`}</h3>
    <p>{`Si le consentement a été donné, ce cookie stocke l'identifiant unique de l'appareil utilisé par d'autres services d'analyse pour associer les évènements à la même session. Cet identifiant n'est pas partagé avec des sites tiers et n'est pas nécessairement le même pour un même utilisateur.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Catégorie:`}</strong>{` Statistiques`}</li>
      <li parentName="ul"><strong parentName="li">{`Collecté:`}</strong>{` Quand le consentement pour l'un des services de statistique est donné`}</li>
      <li parentName="ul"><strong parentName="li">{`Utilisé par:`}</strong>{` `}<a parentName="li" {...{
          "href": "https://segment.com/legal/privacy/"
        }}>{`Segment`}</a></li>
      <li parentName="ul"><strong parentName="li">{`Partagé avec:`}</strong>{` Google Tag Manager`}</li>
      <li parentName="ul"><strong parentName="li">{`Expiration:`}</strong>{` 1 an`}</li>
    </ul>
    <h3 {...{
      "id": "factor-segment-consents"
    }}>{`factor-segment-consents`}</h3>
    <p>{`Stocke les préférences de consentement actuelles`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Catégorie:`}</strong>{` Consentement`}</li>
      <li parentName="ul"><strong parentName="li">{`Collecté:`}</strong>{` Quand le consentement pour au moins un service est donné ou refusé`}</li>
      <li parentName="ul"><strong parentName="li">{`Utilisé par:`}</strong>{` Lleed & Partners`}</li>
      <li parentName="ul"><strong parentName="li">{`Expiration:`}</strong>{` 1 mois`}</li>
    </ul>

    <style {...{
      "className": "grvsc-styles"
    }}>{`
  .grvsc-container {
    overflow: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
    padding-top: 1rem;
    padding-top: var(--grvsc-padding-top, var(--grvsc-padding-v, 1rem));
    padding-bottom: 1rem;
    padding-bottom: var(--grvsc-padding-bottom, var(--grvsc-padding-v, 1rem));
    border-radius: 8px;
    border-radius: var(--grvsc-border-radius, 8px);
    font-feature-settings: normal;
    line-height: 1.4;
  }
  
  .grvsc-code {
    display: table;
  }
  
  .grvsc-line {
    display: table-row;
    box-sizing: border-box;
    width: 100%;
    position: relative;
  }
  
  .grvsc-line > * {
    position: relative;
  }
  
  .grvsc-gutter-pad {
    display: table-cell;
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  .grvsc-gutter {
    display: table-cell;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter::before {
    content: attr(data-content);
  }
  
  .grvsc-source {
    display: table-cell;
    padding-left: 1.5rem;
    padding-left: var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem));
    padding-right: 1.5rem;
    padding-right: var(--grvsc-padding-right, var(--grvsc-padding-h, 1.5rem));
  }
  
  .grvsc-source:empty::after {
    content: ' ';
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter + .grvsc-source {
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  /* Line transformer styles */
  
  .grvsc-has-line-highlighting > .grvsc-code > .grvsc-line::before {
    content: ' ';
    position: absolute;
    width: 100%;
  }
  
  .grvsc-line-diff-add::before {
    background-color: var(--grvsc-line-diff-add-background-color, rgba(0, 255, 60, 0.2));
  }
  
  .grvsc-line-diff-del::before {
    background-color: var(--grvsc-line-diff-del-background-color, rgba(255, 0, 20, 0.2));
  }
  
  .grvsc-line-number {
    padding: 0 2px;
    text-align: right;
    opacity: 0.7;
  }
  
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      